<script setup lang="ts">
import { ExclamationIcon } from '@heroicons/vue/outline';
import { BadgeCheckIcon } from '@heroicons/vue/solid';
import { differenceInMilliseconds, parseISO } from 'date-fns';
import type { PropType } from 'vue';
import Popper from 'vue3-popper';
import { useInfiniteScroll } from '~/composables/infinite-scroll';
import { getApiRoot } from '~/utilities/api';
import { formatXchPrice } from '~/utilities/chia-display-utils';
import { getUrlForCollection } from '~/utilities/url-slugs';

const props = defineProps({
  intervalInDays: String as PropType<'7' | '30' | 'all'>,
  limit: Number,
  rows: { type: Number, default: 1 },
  excludeSus: { type: Boolean, default: false },
  hideNumTrades: { type: Boolean, default: false },
  hideNumItems: { type: Boolean, default: false },
});

const intervalInDays = toRef(props, 'intervalInDays');

const target = ref();
const { items: collections, pending } = useInfiniteScroll(
  target,
  () =>
    `${getApiRoot()}/collections?interval=${intervalInDays.value}&include_nft_count=True&include_floor_price=True${
      props.excludeSus ? '&exclude_sus=True' : ''
    }`,
  [intervalInDays],
  props.limit || 12
);

// const sortDirection = ref(1);
// const sortKey = ref('volume');

const isSuspicious = (collection: any) =>
  collection.suspicious_activity_timeout_until &&
  differenceInMilliseconds(parseISO(collection.suspicious_activity_timeout_until), new Date()) > 0;
</script>
<template>
  <div>
    <div
      class="grid grid-cols-1 divide-x divide-neutral-300 rounded-lg bg-white ring-1 ring-neutral-300 dark:divide-neutral-600 dark:bg-black dark:ring-neutral-600 xl:grid-cols-2"
    >
      <table class="min-w-full table-fixed">
        <thead>
          <tr>
            <th class="w-12 px-3 sm:py-3.5"></th>
            <th class="w-20 px-3 sm:py-3.5"></th>
            <CollectionTopTableHeader header-key="name" hide-below="sm"></CollectionTopTableHeader>
            <CollectionTopTableHeader header-key="volume" hide-below="sm" align="end"
              >{{ $t('collections.volume') }}
            </CollectionTopTableHeader>
            <CollectionTopTableHeader header-key="floor_price" hide-below="sm" align="end" class="pr-6"
              >{{ $t('collections.floorPrice') }}
            </CollectionTopTableHeader>
            <CollectionTopTableHeader
              v-if="!props.hideNumTrades"
              header-key="trade_count"
              hide-below="md"
              align="center"
              >{{ $t('collections.numTrades') }}
            </CollectionTopTableHeader>
            <CollectionTopTableHeader v-if="!props.hideNumItems" header-key="num_items" hide-below="lg" align="center"
              >{{ $t('collections.numItems') }}
            </CollectionTopTableHeader>
          </tr>
        </thead>
        <tbody>
          <NuxtLink
            v-for="(collection, planIdx) in collections.slice(0, 5)"
            :key="collection.id"
            v-slot="{ navigate }"
            :to="getUrlForCollection(collection)"
            custom
          >
            <tr class="cursor-pointer hover:bg-neutral-50 dark:hover:bg-neutral-900" @click="navigate">
              <td
                :class="[
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'relative py-4 pl-4',
                ]"
              >
                <div class="text-center text-xl font-bold text-neutral-600 dark:text-neutral-300">
                  {{ planIdx + 1 }}
                </div>
              </td>
              <td
                :class="[
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'relative py-4 pl-4 pr-3 lg:text-lg',
                ]"
              >
                <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-md group-hover:opacity-75">
                  <div v-if="collection.creator?.verification_state === 2" class="flex items-center justify-center">
                    <ExclamationIcon class="h-1/2 w-1/2 text-red-600" />
                  </div>
                  <img
                    v-else-if="collection.thumbnail_uri"
                    :alt="`${collection.name} image`"
                    :src="collection.thumbnail_uri"
                    loading="lazy"
                    :class="collection.sensitive_content ? 'blur-md' : isSuspicious(collection) ? 'opacity-50' : ''"
                    class="aspect-1 rounded-md object-cover"
                  />
                </div>
              </td>
              <td
                :class="[
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'lg:text-md relative py-8 pl-4 pr-3',
                ]"
              >
                <div
                  :class="isSuspicious(collection) ? 'opacity-50' : ''"
                  class="flex items-center gap-1 font-semibold text-neutral-900 dark:text-neutral-50"
                >
                  <span class="max-w-[200px] truncate">{{ collection.name }}</span>
                  <BadgeCheckIcon
                    v-if="collection.creator?.verification_state === 1"
                    class="h-5 w-5 flex-shrink-0 text-emerald-600"
                  />
                </div>
                <div class="mt-1 flex flex-col font-medium text-neutral-900 dark:text-neutral-50 lg:hidden">
                  <span class="flex items-center gap-1 sm:hidden">
                    <span :class="isSuspicious(collection) ? 'opacity-50' : ''">
                      Volume: {{ formatXchPrice(collection.volume) }}
                    </span>
                    <Popper arrow hover content="Suspicious trading activity (inflated volume)">
                      <ExclamationIcon v-if="isSuspicious(collection)" class="h-5 w-5 text-orange-600" /> </Popper
                  ></span>
                  <span :class="isSuspicious(collection) ? 'opacity-50' : ''" class="sm:hidden"
                    >Floor: {{ collection.floor_price ? formatXchPrice(collection.floor_price) : '---' }}</span
                  >
                  <span
                    v-if="!props.hideNumTrades"
                    :class="isSuspicious(collection) ? 'opacity-50' : ''"
                    class="md:hidden"
                    >Trades: {{ collection.trade_count.toLocaleString('en') }}</span
                  >
                  <span v-if="!props.hideNumItems" class="md:hidden"
                    >Items: {{ collection.nft_count.toLocaleString('en') }}</span
                  >
                </div>
              </td>
              <td
                :class="[
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'lg:text-md hidden w-44 px-3 py-3.5 font-bold text-neutral-900 dark:text-neutral-50 sm:table-cell',
                ]"
              >
                <div class="flex items-center justify-end gap-1">
                  <Popper arrow hover content="Suspicious trading activity (inflated volume)">
                    <ExclamationIcon v-if="isSuspicious(collection)" class="h-5 w-5 text-orange-600" />
                  </Popper>
                  <span :class="isSuspicious(collection) ? 'opacity-50' : ''">
                    {{ formatXchPrice(collection.volume) }}
                  </span>
                </div>
              </td>
              <td
                :class="[
                  isSuspicious(collection) ? 'opacity-50' : '',
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'lg:text-md hidden w-36 py-3.5 pl-3 pr-6 font-bold text-neutral-900 dark:text-neutral-50 sm:table-cell',
                ]"
              >
                <div class="flex items-center justify-end gap-1">
                  <span>
                    {{ collection.floor_price ? formatXchPrice(collection.floor_price) : '---' }}
                  </span>
                </div>
              </td>
              <td
                v-if="!props.hideNumTrades"
                :class="[
                  isSuspicious(collection) ? 'opacity-50' : '',
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'lg:text-md hidden w-36 px-3 py-3.5 text-center font-medium text-neutral-900 dark:text-neutral-50 md:table-cell',
                ]"
              >
                {{ collection.trade_count.toLocaleString('en') }}
              </td>
              <td
                v-if="!props.hideNumItems"
                :class="[
                  isSuspicious(collection) ? 'opacity-50' : '',
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'lg:text-md hidden w-44 px-3 py-3.5 text-center font-medium text-neutral-900 dark:text-neutral-50 lg:table-cell',
                ]"
              >
                {{ collection.nft_count.toLocaleString('en') }}
              </td>
            </tr>
          </NuxtLink>
          <template v-if="pending && !collections?.length">
            <tr v-for="index in 10" :key="index" class="cursor-pointer hover:bg-emerald-50">
              <td
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'relative py-4 pl-4 text-sm sm:pl-6',
                ]"
              ></td>
              <td
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'relative py-4 pl-4 text-sm sm:pl-6',
                ]"
              >
                <div class="aspect-h-1 aspect-w-1 group-hover:opacity-75">
                  <div class="h-full w-full animate-pulse rounded-md bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
              <td
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'relative py-8 pl-4 pr-3 lg:text-lg',
                ]"
              >
                <div class="flex items-center gap-1 font-semibold text-neutral-900 dark:text-neutral-50">
                  <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
              <td
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden w-72 px-3 py-3.5 sm:table-cell',
                ]"
              >
                <div class="flex justify-end">
                  <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
              <td
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden w-36 px-3 py-3.5 font-medium text-neutral-900 dark:text-neutral-50 md:table-cell lg:text-lg',
                ]"
              >
                <div class="flex justify-end">
                  <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
              <td
                v-if="!props.hideNumTrades"
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden w-36 px-3 py-3.5 font-medium text-neutral-900 dark:text-neutral-50 lg:table-cell lg:text-lg',
                ]"
              >
                <div class="flex justify-center">
                  <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
              <td
                v-if="!props.hideNumItems"
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden w-44 px-3 py-3.5 font-medium text-neutral-900 dark:text-neutral-50 lg:table-cell lg:text-lg',
                ]"
              >
                <div class="flex justify-center">
                  <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <table class="hidden min-w-full table-fixed xl:table">
        <thead>
          <tr>
            <th class="w-12 px-3 sm:py-3.5"></th>
            <th class="w-20 px-3 sm:py-3.5"></th>
            <CollectionTopTableHeader header-key="name" hide-below="sm"></CollectionTopTableHeader>
            <CollectionTopTableHeader header-key="volume" hide-below="sm" align="end"
              >{{ $t('collections.volume') }}
            </CollectionTopTableHeader>
            <CollectionTopTableHeader header-key="floor_price" hide-below="sm" align="end" class="pr-6"
              >{{ $t('collections.floorPrice') }}
            </CollectionTopTableHeader>
            <CollectionTopTableHeader
              v-if="!props.hideNumTrades"
              header-key="trade_count"
              hide-below="md"
              align="center"
              >{{ $t('collections.numTrades') }}
            </CollectionTopTableHeader>
            <CollectionTopTableHeader v-if="!props.hideNumItems" header-key="num_items" hide-below="lg" align="center"
              >{{ $t('collections.numItems') }}
            </CollectionTopTableHeader>
          </tr>
        </thead>
        <tbody>
          <NuxtLink
            v-for="(collection, planIdx) in collections.slice(5)"
            :key="collection.id"
            v-slot="{ navigate }"
            :to="getUrlForCollection(collection)"
            custom
          >
            <tr class="cursor-pointer hover:bg-neutral-50 dark:hover:bg-neutral-900" @click="navigate">
              <td
                :class="[
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'relative py-4 pl-4',
                ]"
              >
                <div class="text-center text-xl font-bold text-neutral-600 dark:text-neutral-300">
                  {{ planIdx + 6 }}
                </div>
              </td>
              <td
                :class="[
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'relative py-4 pl-4 pr-3 lg:text-lg',
                ]"
              >
                <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-md group-hover:opacity-75">
                  <div v-if="collection.creator?.verification_state === 2" class="flex items-center justify-center">
                    <ExclamationIcon class="h-1/2 w-1/2 text-red-600" />
                  </div>
                  <img
                    v-else-if="collection.thumbnail_uri"
                    :alt="`${collection.name} image`"
                    :src="collection.thumbnail_uri"
                    loading="lazy"
                    :class="collection.sensitive_content ? 'blur-md' : isSuspicious(collection) ? 'opacity-50' : ''"
                    class="aspect-1 rounded-md object-cover"
                  />
                </div>
              </td>
              <td
                :class="[
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'lg:text-md relative py-8 pl-4 pr-3',
                ]"
              >
                <div
                  :class="isSuspicious(collection) ? 'opacity-50' : ''"
                  class="flex items-center gap-1 font-semibold text-neutral-900 dark:text-neutral-50"
                >
                  <span class="max-w-[200px] truncate">{{ collection.name }}</span>
                  <BadgeCheckIcon
                    v-if="collection.creator?.verification_state === 1"
                    class="h-5 w-5 flex-shrink-0 text-emerald-600"
                  />
                </div>
                <div class="mt-1 flex flex-col font-medium text-neutral-900 dark:text-neutral-50 lg:hidden">
                  <span class="flex items-center gap-1 sm:hidden">
                    <span :class="isSuspicious(collection) ? 'opacity-50' : ''">
                      Volume: {{ formatXchPrice(collection.volume) }}
                    </span>
                    <Popper arrow hover content="Suspicious trading activity (inflated volume)">
                      <ExclamationIcon v-if="isSuspicious(collection)" class="h-5 w-5 text-orange-600" /> </Popper
                  ></span>
                  <span :class="isSuspicious(collection) ? 'opacity-50' : ''" class="sm:hidden"
                    >Floor: {{ collection.floor_price ? formatXchPrice(collection.floor_price) : '---' }}</span
                  >
                  <span
                    v-if="!props.hideNumTrades"
                    :class="isSuspicious(collection) ? 'opacity-50' : ''"
                    class="md:hidden"
                    >Trades: {{ collection.trade_count.toLocaleString('en') }}</span
                  >
                  <span v-if="!props.hideNumItems" class="md:hidden"
                    >Items: {{ collection.nft_count.toLocaleString('en') }}</span
                  >
                </div>
              </td>
              <td
                :class="[
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'lg:text-md hidden w-44 px-3 py-3.5 font-bold text-neutral-900 dark:text-neutral-50 sm:table-cell',
                ]"
              >
                <div class="flex items-center justify-end gap-1">
                  <Popper arrow hover content="Suspicious trading activity (inflated volume)">
                    <ExclamationIcon v-if="isSuspicious(collection)" class="h-5 w-5 text-orange-600" />
                  </Popper>
                  <span :class="isSuspicious(collection) ? 'opacity-50' : ''">
                    {{ formatXchPrice(collection.volume) }}
                  </span>
                </div>
              </td>
              <td
                :class="[
                  isSuspicious(collection) ? 'opacity-50' : '',
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'lg:text-md hidden w-36 py-3.5 pl-3  pr-6 font-bold text-neutral-900 dark:text-neutral-50 sm:table-cell',
                ]"
              >
                <div class="flex items-center justify-end gap-1">
                  <span>
                    {{ collection.floor_price ? formatXchPrice(collection.floor_price) : '---' }}
                  </span>
                </div>
              </td>
              <td
                v-if="!props.hideNumTrades"
                :class="[
                  isSuspicious(collection) ? 'opacity-50' : '',
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'lg:text-md hidden w-36 px-3 py-3.5 text-center font-medium text-neutral-900 dark:text-neutral-50 md:table-cell',
                ]"
              >
                {{ collection.trade_count.toLocaleString('en') }}
              </td>
              <td
                v-if="!props.hideNumItems"
                :class="[
                  isSuspicious(collection) ? 'opacity-50' : '',
                  planIdx === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'lg:text-md hidden w-44 px-3 py-3.5 text-center font-medium text-neutral-900 dark:text-neutral-50 lg:table-cell',
                ]"
              >
                {{ collection.nft_count.toLocaleString('en') }}
              </td>
            </tr>
          </NuxtLink>
          <template v-if="pending && !collections?.length">
            <tr v-for="index in 10" :key="index" class="cursor-pointer hover:bg-emerald-50">
              <td
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'relative py-4 pl-4 text-sm sm:pl-6',
                ]"
              ></td>
              <td
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'relative py-4 pl-4 text-sm sm:pl-6',
                ]"
              >
                <div class="aspect-h-1 aspect-w-1 group-hover:opacity-75">
                  <div class="h-full w-full animate-pulse rounded-md bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
              <td
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'relative py-8 pl-4 pr-3 lg:text-lg',
                ]"
              >
                <div class="flex items-center gap-1 font-semibold text-neutral-900 dark:text-neutral-50">
                  <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
              <td
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden w-72 px-3 py-3.5 sm:table-cell',
                ]"
              >
                <div class="flex justify-end">
                  <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
              <td
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden w-36 px-3 py-3.5 font-medium text-neutral-900 dark:text-neutral-50 md:table-cell lg:text-lg',
                ]"
              >
                <div class="flex justify-end">
                  <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
              <td
                v-if="!props.hideNumTrades"
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden w-36 px-3 py-3.5 font-medium text-neutral-900 dark:text-neutral-50 lg:table-cell lg:text-lg',
                ]"
              >
                <div class="flex justify-center">
                  <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
              <td
                v-if="!props.hideNumItems"
                :class="[
                  index === 0
                    ? 'border-neutral-300 dark:border-neutral-600 sm:border-t'
                    : 'border-t border-neutral-200 dark:border-neutral-700',
                  'hidden w-44 px-3 py-3.5 font-medium text-neutral-900 dark:text-neutral-50 lg:table-cell lg:text-lg',
                ]"
              >
                <div class="flex justify-center">
                  <div class="h-6 w-3/4 animate-pulse rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="collections && !props.limit" ref="target" />
  </div>
</template>

<style scoped>
:deep(.popper) {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 12px;
  --popper-theme-padding: 4px 8px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  font-size: 12px;
}
</style>
